import React, { useEffect, useState } from "react";

import { Button, Table, Tooltip } from "antd";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../loading/Loading";
import GlobalHeader from "../../common/globalHeader";
import { PAGE_LIMIT } from "../../assets/constants/global";
import UpdatePasswordModal from "../../models/updatePasswordModal";
import dayjs from "dayjs";

export default function Tier1Screen() {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };
  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY HH:MM:ss");
      },
    },
    {
      title: "Member ID",
      dataIndex: "memberID",
      key: "memberID",
      render: (text, record) => (
        <a
          onClick={() =>
            navigate(`/tier1/info/detail1/${record._id}`, {
              state: {
                memberID: record.memberID,
              },
            })
          }
          className="member-id-link"
        >
          {text || "N/A"}
        </a>
      ),
    },
    {
      title: "Reference ID",
      dataIndex: ["referee", "memberID"],
      key: "referee",
      render: (text) => text || "N/A",
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      key: "approvedDate",
      render: (text) =>
        text
          ? new Date(text).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : "N/A",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => text || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => text || "N/A",
    },
    {
      title: "My Circle",
      dataIndex: "myCircle",
      key: "myCircle",
      render: (value, record) => {
        const myCircleMembersCount = value || 0;
        return (
          // <Tooltip title="prompt text">
          <span
            style={{
              color:
                myCircleMembersCount < 3 && record?.isNotificationSent
                  ? "red"
                  : "black",
            }}
          >
            {myCircleMembersCount}
          </span>
          // </Tooltip>
        );
      },
    },
    // {
    //   title: "User Name",
    //   dataIndex: "username",
    //   key: "username",
    //   render: (text) => text || "N/A",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        return record?.isSuspended ? "Suspended" : value;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button onClick={() => navigate(`/tier1/${record?._id}`)}>Edit</Button>
      ),
    },
    {
      title: "Password Update",
      key: "action",
      render: (record) => (
        <Button onClick={() => setIsModalVisible(record?._id)}>
          Change Password
        </Button>
      ),
    },
  ];
  const handleModalOk = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    setSearchParams({ pageNumber: 1 });
  }, []);
  if (!data) return <Loading />;

  return (
    <>
      <div className="main_container_div">
        <GlobalHeader
          textHeadline={
            <span className="primary-heading" style={{ color: "black" }}>
              Tier 1 users
            </span>
          }
          showCompanyLink={true}
        />

        <div className="main_inner_div">
          <Table
            className="text-hide"
            dataSource={data.data}
            columns={columns}
            onChange={(page) => handleTableChange(page)}
            pagination={{
              pageSize: PAGE_LIMIT,
              total: data?.length,
            }}
            scroll={{
              x: 700,
            }}
          />
          <UpdatePasswordModal
            visible={!!isModalVisible}
            recordId={isModalVisible}
            onOk={handleModalOk}
            onCancel={() => setIsModalVisible(false)}
            tier="tier-1"
          />
        </div>
      </div>
    </>
  );
}
