import React, { cloneElement, useState } from "react";
import { useRevalidator } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Spin, Upload, Button, Form, Input } from "antd";
import { createCategories } from "../services/helpers";

function AddCategoriesModal({ children }) {
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const { name, thumbnail } = values;
      const file = thumbnail.fileList[0].originFileObj;

      const formData = new FormData();
      formData.append('name', name)
      formData.append('thumbnail', file)
      await createCategories(formData);

      revalidator.revalidate();
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error('error :>> ', error);
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const props = {
    name: "file",
  };

  return (
    <div>
      {children &&
        cloneElement(children, {
          onClick: showModal,
        })}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ padding: "20px" }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              className="text-hide"
              label="Category Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your category name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="text-hide"
              label="Thumbnail"
              name="thumbnail"
              rules={[
                {
                  required: true,
                  message: "Please select your thumbnail photo!",
                },
              ]}
            >
              <Upload {...props} showUploadList={true} maxCount={1}>
                <Button className="text-hide" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                className="text-hide"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddCategoriesModal;
