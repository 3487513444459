import React, { useEffect, useState } from "react";

import { Button, Table } from "antd";

import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../loading/Loading";
import GlobalHeader1 from "../../common/globalHeader1";
import { PAGE_LIMIT } from "../../assets/constants/global";
import UpdatePasswordModal from "../../models/updatePasswordModal";
import dayjs from "dayjs";

export default function Tier3Screen() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const data = useLoaderData();
  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY HH:MM:ss");
      },
    },
    {
      title: "Member ID",
      dataIndex: "memberID",
      key: "memberID",
      render: (text, record) => (
        <a
          onClick={() =>
            navigate(`/tier3/info/detail3/${record?._id}`, {
              state: {
                memberID: record?.memberID,
                referee: record?.referee,
                interviewers: record?.interviewers,
              },
            })
          }
          className="member-id-link"
        >
          {text || "N/A"}
        </a>
      ),
    },
    {
      title: "Referee",
      dataIndex: ["referee", "memberID"],
      key: "referee",
      render: (text) => text || "N/A",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => text || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => text || "N/A",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "N/A",
    },
    {
      title: "Claims",
      dataIndex: "totalClaims",
      key: "totalClaims",
      render: (text) => text || "0",
    },
    {
      title: "Edit",
      key: "action",
      render: (record) => (
        <Button
          onClick={() =>
            navigate(`/tier3/${record?._id}`, {
              state: record,
            })
          }
        >
          Edit
        </Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        return record?.isSuspended ? "Suspended" : value;
      },
    },
    {
      title: "Password Update",
      key: "action",
      render: (record) => (
        <Button onClick={() => setIsModalVisible(record?._id)}>
          Change Password
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setSearchParams({ pageNumber: 1 });
  }, []);

  if (!data?.data) return <Loading />;

  return (
    <div className="main_container_div">
      <GlobalHeader1
        textHeadline={
          <span
            className="primary-heading"
            style={{ color: "black", marginRight: "77px" }}
          >
            Tier 3 users
          </span>
        }
      />{" "}
      <div className="main_inner_div">
        <Table
          dataSource={data?.data}
          columns={columns}
          onChange={(page) => handleTableChange(page)}
          pagination={{
            pageSize: PAGE_LIMIT,
            total: data?.length,
          }}
          scroll={{
            x: 700,
          }}
        />
      </div>
      <UpdatePasswordModal
        visible={!!isModalVisible}
        recordId={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
        tier="tier-3"
      />
    </div>
  );
}
