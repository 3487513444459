import React, { useEffect } from "react";
import {
  NavLink,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import { PAGE_LIMIT } from "../../assets/constants/global";
import Loading from "../loading/Loading";

const CompanyScreen = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY HH:MM:ss");
      },
    },
    {
      title: "Company ID",
      dataIndex: "companyID",
      key: "companyID",
      render: (text, record) => {
        return (
          <a onClick={() =>
            navigate(`/tier2/company/info/${record._id}`)}
            className="member-id-link"
          >
            {text || "N/A"}
          </a>
        )
      }
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text || "N/A",
    },
    {
      title: "Address Street",
      dataIndex: "addressStreet",
      key: "addressStreet",
      render: (text) => text || "N/A",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => text || "N/A",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (text) => text || "N/A",
    },
    {
      title: "Zip Code",
      dataIndex: "zipCode",
      key: "zipCode",
      render: (text) => text || "N/A",
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      render: (text) => text || "N/A",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button
          onClick={() => navigate(`/tier2/company/companyForm/${record?._id}`)}
        >
          Edit
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setSearchParams({ pageNumber: 1 });
  }, []);

  if (!data) return <Loading />;

  return (
    <div className="main_container_div">
      {/* header title and add button */}
      <div className="inner_div_2 main-header">
        <Typography className="heading_text">
          <span className="primary-heading" style={{ color: "black" }}>
            Tier 2 Companies
          </span>
        </Typography>
        <NavLink to="/tier2/company/companyForm">
          <Button className="text-hide">Add+</Button>
        </NavLink>
      </div>

      <div className="main_inner_div">
        <Table
          className="text-hide"
          dataSource={data.data}
          columns={columns}
          onChange={(page) => handleTableChange(page)}
          pagination={{
            pageSize: PAGE_LIMIT,
            total: data?.length,
          }}
          scroll={{
            x: 700,
          }}
        />
      </div>
    </div>
  );
};

export default CompanyScreen;
