import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../config";

export const uploadCompanyLogo = (path, companyId, file) => {
  return new Promise((resolve, reject) => {
    const extention = file?.name?.split(".").pop() || "jpg";
    const storageReference = ref(storage, `${path}/${companyId}.jpg`);
    const task = uploadBytesResumable(storageReference, file);
    const taskProgress = () => {};
    const taskError = reject;
    const taskCompleted = async () => {
      await getDownloadURL(task.snapshot.ref)
        .then(async (downloadURL) => {
          resolve(downloadURL);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  });
};

export const deleteCompanyLogo = (path, companyId) => {
  return new Promise((resolve, reject) => {
    const storageReference = ref(storage, `${path}/${companyId}.jpg`);
    deleteObject(storageReference)
      .then(() => {
        resolve("Ok");
      })
      .catch((error) => {
        resolve("Ok"); // temporary
        // reject(error);
      });
  });
};

export const uploadProfilePicture = (path, file) => {
  return new Promise((resolve, reject) => {
    const uniqueKey = new Date().getUTCMilliseconds();
    const storageReference = ref(storage, `${path}/${uniqueKey}-${file.name}`);
    const task = uploadBytesResumable(storageReference, file);
    const taskProgress = () => {};
    const taskError = reject;
    const taskCompleted = async () => {
      await getDownloadURL(task.snapshot.ref)
        .then(async (downloadURL) => {
          resolve(downloadURL);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  });
};

export const saveVCard = (vCardObj) => {
  const vCardString = vCardObj.toString();
  const blob = new Blob([vCardString], { type: "text/vcard" });
  const url = window?.URL?.createObjectURL(blob);
  console.log("chk url", url);
  const a = document.createElement("a");
  a.href = url;
  a.download = "contact.vcf";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
