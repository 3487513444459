import React, { cloneElement, useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Modal, Spin, Upload, Button, Form, Input } from "antd";

import { useRevalidator } from "react-router-dom";
import { editCategories } from "../services/helpers";

function EditCategoriesModal({ children, item }) {
  const [form] = Form.useForm();
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(item?.thumbnail || null);
  const [imageData, setImageData] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUploadChange = async (info) => {
    // Update preview image when new file uploaded
    if (info.fileList && info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      setImageData(file)
    } else {
      setPreviewImage(item?.thumbnail || null);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const { name } = values;
      const formData = new FormData();
      if (name) {
        formData.append('name', name)
      }
      if (imageData) {
        formData.append('thumbnail', imageData)
      }
      await editCategories(formData, item?._id);
      revalidator.revalidate();
      form.resetFields();
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };
  const props = {
    name: "file",
    onChange: handleUploadChange,
    // action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    // headers: {
    //   authorization: "authorization-text",
    // },
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({ name: item?.name });
      setPreviewImage(item?.thumbnail || null);
    }
  }, [item, form]);

  return (
    <div>
      {children &&
        cloneElement(children, {
          onClick: showModal,
        })}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ padding: "20px" }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Form.Item label="Category Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Thumbnail" name="thumbnail"
              rules={[
                {
                  required: !item?.thumbnail,
                  message: "Please select your thumbnail photo!",
                },
              ]}
            >
              <Upload {...props} showUploadList={true} maxCount={1}>
                <Button className="text-hide" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                className="text-hide"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default React.memo(EditCategoriesModal);
