import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { TbX } from "react-icons/tb";
import { Button, Form, Input } from "antd";
import { useSearchParams } from "react-router-dom";

export default function SearchBox() {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("pageNumber");

  const onFinish = (values) => {
    const { query } = values;
    setSearchParams({ pageNumber: pageNumber, query: query });
  };

  const onFinishFailed = (errorInfo) => {
  };

  const handleClear = () => {
    form.resetFields();
    setSearchParams({ pageNumber: pageNumber });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".search-box-container")) {
        const queryField = form.getFieldValue("query");
        if (!queryField) {
          form.resetFields(); // Reset the form fields to clear validation messages
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      className="search-box-container"
    >
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Form.Item
          name="query"
          rules={[
            {
              required: true,
              message: "Please Type anything to Search!",
            },
          ]}
          style={{ flexGrow: 1 }}
        >
          <Input
            className="text-hide"
            placeholder="Search Here"
            suffix={
              <TbX
                style={{ fontSize: "16px", cursor: "pointer" }}
                onClick={handleClear}
              />
            }
          />
        </Form.Item>
        <Form.Item>
          <Button className="text-hide" type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Box>
    </Form>
  );
}
